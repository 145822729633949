import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
// import ButtonGroup from '../elements/ButtonGroup';
// import Image from '../elements/Image';
// import Modal from '../elements/Modal';
import Gallery from '../../components/Gallery';
import Layout from '../../components/Layout';
import EmailModal from '../elements/EmailModal';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [modalActive, setModalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setModalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setModalactive(false);
  };

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  console.log(props.images);
  console.log(modalActive);

  return (
    <section {...props} className={outerClasses}>
      <div className="container-xl">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Your <span className="text-color-primary">AI</span>es
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Deixe de monitorar, passe a enxergar.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <EmailModal />
                {/* <Button
                    tag="a"
                    color="dark"
                    wideMobile
                    href="https://github.com/cruip/open-react-template/"
                  >
                    View on Github
                  </Button> */}
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}
            <Layout>
              <Gallery images={props.images} />
            </Layout>
            {/* <Image
                className="has-shadow"
                src={require('./../../assets/images/fulls/01.jpg')}
                alt="Hero"
              /> */}
            {/* </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
