import React, { Component } from 'react';
// import sections
import Hero from '../components/sections/Hero';
// import Layout from '../components/Layout';
// import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
// import Gallery from '../components/Gallery';
import Cta from '../components/sections/Cta';
// import Main from '../assets/sass/main.scss';
// import Style from '../assets/sass/style.scss';

class Home extends Component {
  render() {
    return (
      <>
        <Hero className="illustration-section-01" images={this.props.images} />
        {/* <FeaturesTiles /> */}
        {/* <FeaturesSplit
          invertMobile
          topDivider
          imageFill
          className="illustration-section-02"
        />
        <Testimonial topDivider /> */}
        {/* <Cta split /> */}
      </>
    );
  }
}

export default Home;
