import React from 'react';
import Button from '../elements/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#ff6139',
      dark: '#002884',
      contrastText: '#000',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <ThemeProvider theme={theme}>
        <Button tag="a" color="primary" wideMobile onClick={handleClickOpen}>
          Entrar em contato
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form
            action="https://getform.io/f/c20d4763-81b5-49a9-9a5e-f39d135dcdc5"
            method="POST"
          >
            <DialogTitle id="form-dialog-title">
              <span className="modal-title">Como podemos ajudar?</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Entre em contato com o nosso time e tire todas as suas dúvidas
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="nome"
                name="Nome"
                placeholder="Nome"
                type="text"
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                margin="dense"
                id="email"
                name="Email"
                placeholder="Email"
                type="email"
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                margin="dense"
                id="empresa"
                name="Empresa"
                placeholder="Empresa"
                type="text"
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                margin="dense"
                id="mensagem"
                name="Mensagem"
                placeholder="Mensagem"
                type="text"
                fullWidth
                required
                multiline="true"
                variant="outlined"
                className="multiline-form"
              />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <button color="primary" type="submit">
                Enviar
              </button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
